.ticket-showtime-title {
  text-align: start;
  padding-top: 32px;
}

.line {
  display: flex;
  justify-content: end;
  padding-top: 6px;
  border-bottom: 1px solid var(--color-grey-20);
}

.showtime-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 18px;
}

.showdate-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.showtime-item-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.showtime-item {
  width: fit-content;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid var(--color-grey-20);
  background-color: var(--color-white-100);
  font-weight: 500;
}

.showtime-item:hover {
  border: 1px solid var(--color-primary-100);
  background-color: var(--color-primary-10);
  cursor: pointer;
}
