@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-white-100: #fefefe;

  --color-grey-100: #1a1a1a;
  --color-grey-80: #484848;
  --color-grey-60: #767676;
  --color-grey-40: #a4a4a4;
  --color-grey-20: #d2d2d2;
  --color-grey-10: #e3e3e3;
  --color-grey-5: #ededed;

  --color-primary-100: #5925dc;
  --color-primary-80: #7a5af8;
  --color-primary-60: #9b8afb;
  --color-primary-40: #bdb4fe;
  --color-primary-20: #d9d6fe;
  --color-primary-10: #f4f3ff;

  --color-success-100: #2bb930;
  --color-success-50: #95dc98;
  --color-success-10: #eaf8ea;

  --color-warning-100: #ff9514;
  --color-warning-50: #ffca8a;
  --color-warning-10: #fff4e7;

  --color-danger-100: #e6131b;
  --color-danger-50: #f3898d;
  --color-danger-10: #fde7e8;
}

body {
  margin: 0px;
}

a {
  text-decoration: none;
}

h1 {
  color: var(--color-grey-100);
  font-size: 72px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 700;
  line-height: 82px;
  text-align: center;
}

h2 {
  color: var(--color-grey-100);
  font-size: 64px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 700;
  line-height: 74px;
  text-align: center;
}

h3 {
  font-size: 52px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
  text-align: center;
}

h4 {
  font-size: 42px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  text-align: center;
  margin: 0px;
}

h5 {
  font-size: 32px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  margin: 0px;
}

h6 {
  font-size: 24px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0px;
}

.headline-text {
  color: var(--color-grey-100);
  font-size: 20px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
}

.button-medium-text {
  color: var(--color-white-100);
  text-align: center;
  font-size: 16px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.button-small-text {
  color: var(--color-white-100);
  text-align: center;
  font-size: 14px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
}

.label-text {
  color: var(--color-grey-100);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.placeholder-text {
  color: var(--color-grey-60);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.289px;
}

.paragraph-normal {
  color: var(--color-grey-100, #1a1a1a);
  font-size: 16px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.paragraph-small {
  font-size: 14px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.description-text {
  font-size: 12px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.color-danger {
  color: var(--color-danger-100);
}

.logotype {
  color: var(--color-grey-100);
  font-size: 18px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

.tag-id {
  background-color: var(--color-grey-5);
  border-radius: 6px;
  border: 1px solid var(--color-grey-40);
  color: var(--color-grey-60);
  font-size: 14px;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  padding: 4px 8px;
}

.tag-warning {
  background-color: var(--color-warning-10);
  border-radius: 6px;
  border: 1px solid var(--color-warning-100);
  color: var(--color-warning-100);
  font-weight: 600;
  padding: 4px 8px;
  text-transform: uppercase;
}

.tag-success {
  background-color: var(--color-success-10);
  border-radius: 6px;
  border: 1px solid var(--color-success-100);
  color: var(--color-success-100);
  font-weight: 600;
  padding: 4px 8px;
  text-transform: uppercase;
}

.tag-danger {
  background-color: var(--color-danger-10);
  border-radius: 6px;
  border: 1px solid var(--color-danger-100);
  color: var(--color-danger-100);
  font-weight: 600;
  padding: 4px 8px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  h2 {
    font-size: 52px;
    line-height: 62px;
  }

  h3 {
    font-size: 42px;
    line-height: 52px;
  }

  h4 {
    font-size: 32px;
    line-height: 38px;
  }

  h5 {
    font-size: 24px;
    line-height: 34px;
  }

  h6 {
    font-size: 18px;
  }

  .headline-text {
    font-size: 16px;
  }

  .paragraph-normal {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 42px;
    line-height: 52px;
  }

  h3 {
    font-size: 32px;
    line-height: 38px;
  }

  h6 {
    font-size: 16px;
  }

  .headline-text {
    font-size: 14px;
  }

  .paragraph-small {
    font-size: 12px;
    line-height: 20px;
  }
}
