.balance-modal {
  width: 450px;
  height: 250px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--color-white-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: 0;
}

.balance-modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balance-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 8px;
}

.balance-input {
  font-size: 52px;
  max-width: 350px;
  width: 1ch;
  font-family: Urbanist;
  font-style: normal;
  font-weight: 600;
  color: var(--color-primary-100);
}

.select-balance-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

@media (max-width: 480px) {
  .balance-modal {
    width: 275px;
    max-width: 275px;
    border-radius: 15px;
  }

  .balance-input {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .balance-modal {
    width: 350px;
    height: 200px;
  }

  .balance-input {
    font-size: 42px;
  }
}
