.ticket-page {
  max-width: 600px;
  margin: auto;
  padding-top: 0px;
  padding-bottom: 54px;
}

.ticket-title {
  margin: 24px 0px;
}

.tickets-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1240px) {
  .ticket-page {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .ticket-page {
    padding-top: 0px;
    padding-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .ticket-page {
    padding-left: 16px;
    padding-right: 16px;
  }
}
