.label-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container {
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid var(--color-grey-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-input {
  border: none;
  width: 100%;
}

.label-input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
