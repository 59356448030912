.ticket-card-grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 24px;
  padding: 16px;
  border-radius: 12px;
  border: 2px solid var(--color-grey-20);
  cursor: pointer;
}

.ticket-card-grid:hover {
    background-color: var(--color-primary-10);
  border: 2px solid var(--color-primary-100);
}

.ticket-card-details {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}

.ticket-card-details h6 {
  text-align: start;
}

.seat-numbers-ticket {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
}

.icon-ticket {
  font-size: 18px;
  color: var(--color-grey-60);
}
