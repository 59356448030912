.page-template {
  max-width: 1240px;
  margin: auto;
  padding-top: 54px;
  padding-bottom: 54px;
}

.balance-card {
  aspect-ratio: 8.5 / 5.3;
  width: 450px;
  margin: auto;
  border-radius: 20px;
  color: var(--color-white-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 32px;
  align-items: center;
}

.balance-card-content {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.balance-card h3 {
  margin: 0px;
}

.balance-card .headline-text {
  color: var(--color-white-100);
}

.button-balance-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
}

@media (max-width: 1240px) {
  .page-template {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 480px) {
  .page-template {
    padding-left: 16px;
    padding-right: 16px;
  }

  .balance-card {
    max-width: 250px;
    background-color: aqua;
    border-radius: 15px;
  }
}

@media (max-width: 768px) {
  .page-template {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .balance-card {
    width: 350px;
  }
}
