.button-small {
  border-radius: 6px;
  border: 1px solid var(--color-primary-100);
  background-color: var(--color-primary-100);
  padding: 12px;
}

.button-small:hover {
  cursor: pointer;
  background-color: var(--color-primary-10);
  color: var(--color-primary-100);
}

.button-small-danger {
  border-radius: 6px;
  border: 1px solid var(--color-danger-100);
  background-color: var(--color-danger-100);
  padding: 12px;
}

.button-small-danger:hover {
  cursor: pointer;
  background-color: var(--color-white-100);
  color: var(--color-danger-100);
}
