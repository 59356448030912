.button-medium {
  border-radius: 6px;
  background-color: var(--color-primary-100);
  border: 2px solid var(--color-primary-100);
  padding: 12px;
}

.button-medium:hover {
  cursor: pointer;
  background-color: var(--color-primary-10);
  color: var(--color-primary-100);
}

.button-medium-danger {
  border-radius: 6px;
  border: 1px solid var(--color-danger-100);
  background-color: var(--color-danger-100);
  padding: 12px;
}

.button-medium-danger:hover {
  cursor: pointer;
  background-color: var(--color-white-100);
  color: var(--color-danger-100);
}
