.book-page {
  max-width: 500px;
  margin: auto;
  padding-top: 54px;
  padding-bottom: 54px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.seat-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.seat-legend-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.seat {
  aspect-ratio: 1/1;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-disabled {
  background-color: var(--color-grey-60);
  border: 1px solid var(--color-grey-60);
}

.seat-available {
  background-color: var(--color-primary-100);
  border: 1px solid var(--color-primary-100);
  cursor: pointer;
}

.seat-selected {
  background-color: var(--color-primary-10);
  border: 1px solid var(--color-primary-100);
  cursor: pointer;
}

.seat-available .paragraph-normal {
  color: var(--color-white-100);
  font-weight: 600;
}

.seat-disabled .paragraph-normal {
  color: var(--color-white-100);
  font-weight: 600;
}

.seat-selected .paragraph-normal {
  color: var(--color-primary-100);
  font-weight: 600;
}

.seat-grid {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  justify-self: center;
  align-self: center;
}

.seat-summary-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.seat-summary-grid .paragraph-normal {
  text-align: center;
}

.total-price-container {
  display: flex;
  flex-direction: column;
}

.selected-seats-container {
  display: flex;
  flex-direction: column;
}

.warning-max-seat {
  text-align: center;
  color: var(--color-danger-100);
}

@media (max-width: 1240px) {
  .book-page {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .book-page {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (max-width: 560px) {
  .seat {
    width: 25px;
  }
}

@media (max-width: 480px) {
  .book-page {
    padding-left: 16px;
    padding-right: 16px;
  }

  .seat-legend {
    gap: 12px;
  }

  .seat-legend-item {
    gap: 4px;
    flex-direction: column;
  }

  .seat-grid {
    grid-gap: 5px;
  }
}
