.payment-page {
  max-width: 500px;
  margin: auto;
  padding-top: 54px;
  padding-bottom: 54px;
}

.payment-poster-details {
  width: 100%;
  padding-bottom: 150%;
  border-radius: 6px;
}

.payment-movie-details {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 16px;
}

.payment-movie-details h4 {
  text-align: start;
}

.payment-page .line {
  padding-top: 24px;
  margin-bottom: 16px;
}

.transaction-details-payment {
}

.transaction-details-payment h6 {
  text-align: start;
  padding-bottom: 14px;
}

.row-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.row-total-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;
}

.row-total-price h6 {
  color: var(--color-primary-100);
}

.selected-seats {
  font-weight: 600;
}

.my-balance-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cancel-topup-button {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.message-payment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 16px;
  /* gap: 4px; */
  width: 80%;
  margin: auto;
}

@media (max-width: 1240px) {
  .payment-page {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .payment-page {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .payment-page {
    padding-left: 16px;
    padding-right: 16px;
  }
}
