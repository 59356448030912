.movie-details-page {
  max-width: 1240px;
  margin: auto;
  padding-top: 54px;
  padding-bottom: 54px;
}

.movie-details-container {
  display: grid;
  grid-template-columns: 2fr 5fr;
  gap: 32px;
}

.poster-details {
  width: 100%;
  padding-bottom: 150%;
  border-radius: 20px;
}

.title-details {
  text-align: start;
}

.price-details {
  text-align: start;
  color: var(--color-primary-100);
}

.age-rating-details {
  background-color: var(--color-white-100);
  border: 1px solid var(--color-primary-100);
  color: var(--color-primary-100);
  text-align: start;
  border-radius: 20px;
  width: fit-content;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  margin: auto 0px;
}

.price-age-container-details {
  display: flex;
  gap: 16px;
}

.description-details {
  margin: 24px 0px 8px 0px;
}

.released-details {
  margin-bottom: 24px;
}

@media (max-width: 1240px) {
  .movie-details-page {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 480px) {
  .movie-details-page {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 768px) {
  .movie-details-page {
    padding-top: 24px;
  }

  .movie-details-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .poster-details {
    padding-bottom: 75%;
  }
}

@media (max-width: 480px) {
  .movie-details-page {
    padding-top: 12px;
  }

  .poster-details {
    padding-bottom: 150%;
  }
}
