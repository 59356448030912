.age-rating-modal {
  width: 300px;
  height: 250px;
  padding: 16px 16px 16px 16px;
  border-radius: 10px;
  background-color: var(--color-white-100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: 0;
}

.age-rating-modal-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.age-rating-icon {
  margin-left: auto;
  margin-right: auto;
  font-size: 96px;
  color: var(--color-danger-100);
}

@media (max-width: 480px) {
  .age-rating-modal {
    width: 275px;
    max-width: 275px;
    border-radius: 15px;
  }
}

@media (max-width: 768px) {
  .age-rating-modal {
    width: 250px;
    height: 200px;
  }
}
