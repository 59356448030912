.navbar {
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white-100);
}

.navbar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.sign-up-nav {
  color: var(--color-primary-100);
}

.log-in-nav {
  border-radius: 20px;
  background-color: var(--color-primary-100);
  padding: 6px 21px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.logo-container:hover .logotype {
  color: var(--color-primary-100);
}

.logo {
  fill: var(--color-primary-100);
}

.nav {
  color: var(--color-grey-60);
}

.nav:hover {
  cursor: pointer;
  color: var(--color-primary-100);
}

.active {
  color: var(--color-grey-100);
}

@media (max-width: 560px) {
  .logo-container {
    display: none;
  }

  .navbar {
    justify-content: center;
    padding: 20px 8px;
  }
}

@media (max-width: 560px) {
  .nav {
    font-size: 14px;
  }
}
